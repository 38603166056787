import React, { useState } from "react";
import styles from "./GetInTouch.module.css";

import { Button } from 'reactstrap';
import { post } from "../../utilities/apiClient";
import { useDispatch } from "react-redux";
import { showMessageBox } from "../../redux/messageBox/messageBoxAction";

export default function GetInTouch() {
    const dispatch = useDispatch();
    const [inquirerName, setInquirerName] = useState('');
    const [inquirerEmail, setInquirerEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidContact, setIsValidContact] = useState(true);
    const [inquirerContactNumber, setInquirerContactNumber] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [location, setLocation] = useState('');

    function handleSubmit() {

        if (readyForTrigger()) {
            return;
        }
        else if (validationEmail() && validContactNo()) {
            const data = {
                inquirerName,
                inquirerEmail,
                inquirerContactNumber,
                subject,
                message,
                inquiryTypeID: 1,
                itemCode: "",
                inquirerAddress: "",
                location
            }
            post("Inquiry/PostInquiry", data, true)
                .then(res => {
                    var response: any = res;
                    if (response.data.statusCode === 200) {
                        console.log(response.data);
                        clearData();
                        const msgBox = {
                            show: true,
                            title: "Your inquiry has been submitted",
                            className: "success",
                            content: response.data.errorList[0].statusMessage,
                            isConfirmation: false,
                            callBackFunction: null
                        }
                        dispatch(showMessageBox(msgBox));

                    }
                    else {
                        console.log(response.data.errorList)
                        const msgBox = {
                            show: true,
                            title: "Your inquiry submission failed",
                            className: "error",
                            content: response.data.errorList.statusMessage,
                            isConfirmation: false,
                            callBackFunction: null
                        }
                        dispatch(showMessageBox(msgBox));
                    }
                });
        }
    }
    function clearData() {
        setInquirerName('')
        setInquirerEmail('');
        setInquirerContactNumber('');
        setSubject('');
        setMessage('');
        setLocation('');
    }
    function readyForTrigger() {
        return (inquirerName == "" || inquirerEmail == "" || message == "" || location == "")
    }
    function validationEmail() {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(inquirerEmail) === false) {
            setIsValidEmail(false);
            return false;
        }
        else {
            setIsValidEmail(true);
            return true;
        }
    }
    function validContactNo() {
        if (inquirerContactNumber == '') {
            return true
        }
        if (!(/^[0-9]{10}$/.test(inquirerContactNumber))) {
            setIsValidContact(false)
            return false;
        }
        else {
            setIsValidContact(true);
            return true;
        }
    }

    return (
        <React.Fragment>
            <div>

                <div className={`${styles.formHeading}`}>Get in touch</div>
                <div className={`${styles.formGroupGetInTouch}`}>
                    <input id="Name" name="Name" placeholder="Your Name*" required type="text" className={`${styles.formControlGetInTouch}`}
                        onChange={e => setInquirerName(e.target.value)} value={inquirerName} />
                </div>
                <div className={`${styles.formGroupGetInTouch}`}>
                    <input id="Email" name="Email" placeholder="Your Email*" type="text" className={`${styles.formControlGetInTouch}`}
                        onChange={e => setInquirerEmail(e.target.value)} value={inquirerEmail} />
                    <span style={{ color: "red" }} className={`form-text  col ${!isValidEmail ? '' : 'd-none'}`}> Invalid email address.</span>
                </div>
                <div className={`${styles.formGroupGetInTouch}`}>
                    <input id="Phone" name="Phone" placeholder="Your Phone Number" type="number" className={`${styles.formControlGetInTouch}`}
                        onChange={e => setInquirerContactNumber(e.target.value)} value={inquirerContactNumber} />
                    <span style={{ color: "red" }} className={`form-text  col ${!isValidContact ? '' : 'd-none'}`}> Invalid Phone Number.</span>
                </div>
                <div className={`${styles.formGroupGetInTouch}`}>
                    <input id="Location" name="Location" placeholder="Your Location(City)*" type="text" className={`${styles.formControlGetInTouch}`}
                        onChange={e => setLocation(e.target.value)} value={location} />
                </div>
                <div className={`${styles.formGroupGetInTouch}`}>
                    <input id="Subject" name="Subject" placeholder="Subject" type="text" className={`${styles.formControlGetInTouch}`}
                        onChange={e => setSubject(e.target.value)} value={subject} />
                </div>
                <div className={`${styles.formGroupGetInTouch}`}>
                    <input id="Message" name="Message" placeholder="Your Message*" type="text" className={`${styles.formControlGetInTouch}`}
                        onChange={e => setMessage(e.target.value)} value={message} />
                </div>
                <div className={`${styles.formGroupGetInTouch}`}>
                    <label className={`${styles.formLable}`}>* Required Fields</label>
                </div>
                <div className={`${styles.formGroupGetInTouch}`}>
                    <Button onClick={() => handleSubmit()} disabled={readyForTrigger()}
                        className={`jkoa-blue-btn`} style={{ padding: "0.3rem 2rem" }}>  Submit
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}