import { combineReducers } from "redux";
import { loadingScreenReducer } from "./loadingScreen/loadingScreenReducer";
import { responsiveReducer } from './responsive/responsiveReducer';
import { messageBoxReducer } from './messageBox/messageBoxReducer';
import { searchDataReducer } from "./searchData/searchDataReducer";
import { footerDataReducer } from "./footerData/footerDataReducer";
import { isSiteLiveReducer } from "./isSiteLive/isSiteLiveReducer";
import { urlDataReducer } from './UrlData/UrlDataReducer';

import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    blacklist: [/**'Prevent persist states'*/]
}

const rootReducer = combineReducers({
    loadingScreen: loadingScreenReducer,
    responsive: responsiveReducer,
    messageBox: messageBoxReducer,
    seachData: searchDataReducer,
    footerData: footerDataReducer,
    isSiteLive: isSiteLiveReducer,
    urlData: urlDataReducer
})

export default persistReducer(persistConfig, rootReducer);
export type RootState = ReturnType<typeof rootReducer>