import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown, Container, Modal } from 'react-bootstrap';
import styles from "./NavBarWeb.module.css";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import "./NavBarWeb.module.css";
/*-- Import dependencies --*/
import JKOALogo from '../../../assets/images/logo/KeellsGreenLogo.png';

/*-- Imports for redux --*/
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { setSearchData } from "../../../redux/searchData/searchDataAction";
import { Redirect } from "react-router-dom";
import { get } from "../../../utilities/apiClient";
import { colorCodes, cutomerPortal, productListingType } from "../../../utilities/settings";

let searchingData: { products: any; };

function NavBarWeb() {
    searchingData = useSelector((state: RootState) => state.seachData);
    var departmentList: any = useSelector((state: RootState) => state.footerData.data);

    const dispatch = useDispatch();
    const responsive = useSelector((state: RootState) => state.responsive);
    const [showSearchBar, setShowSearchBar] = useState("none");
    const [showSuggestions, setShowSuggestions] = useState([]);
    const [test, setTest] = useState("&nbsp;");

    useEffect(() => {
        if (searchingData.products.length == 0) {
            getData();
            console.log("searchingData on useeffect", searchingData)
        }
    }, [])

    function getData() {
        get("WebModule/GetSearchingData", {}, false)
            .then(res => {
                var response: any = res;
                if (response.data.statusCode === 200) {
                    dispatch(setSearchData(response.data.result))
                }
                else {
                    console.log(response.data.errorList)
                }
            });
    }

    const searchingDataFn = (event: any) => {

        if (event.target.value.length >= 1) {
            setShowSuggestions(searchingData.products.sort().filter((product: any) => product.itemName.toUpperCase().includes(event.target.value.toUpperCase())));
        }
        else {
            setShowSuggestions([]);
        }
    };

    const hitEnter = (event: any) => {
        if (event.key === 'Enter') {
            window.location.href = "/products/" + productListingType.search.toLowerCase() + "/" + event.target.value.toLowerCase();
        }
    }

    function SearchModal(props: any) {
        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className={`${styles.searchBarMobileContainer}`}>
                    <input className={`${styles.searchBarMobile}`} placeholder="Search"
                        onChange={searchingDataFn}></input>
                    <div className={`${styles.searchContainer} ${styles.fade}`} hidden={showSearchBar == "none" ? true : false}>
                        {
                            props.data.map((item: any) => {
                                return (
                                    <div className={`${styles.searchResult}`}
                                        onClick={() => window.location.href = "/productDetails?ic=" + item.itemCode + "&" + item.itemName.replace(/ /g, "_").toUpperCase()}>
                                        {item.itemName}
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>
        );
    }



    return (
        <React.Fragment>
            <Navbar bg="light" expand="lg" className={`${styles.navBarDefault}`}>
                <Container style={{ justifyContent: 'start' }}>
                    <Navbar.Brand style={{ padding: "1rem 0 1rem 0" }} className="App-logo">
                        <img onClick={() => window.location.href = "/home"} style={{ width: "90px", cursor: "pointer" }} alt="JKOA logo" src="https://sajkoaonline.blob.core.windows.net/jkoa/other/JKOA-logo.png"></img>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className={`${styles.navfull}`}>
                            {responsive.isMobileSmallScreen || responsive.isMobileLargeScreen || responsive.isTabScreen ? null :
                                <Nav.Link href="/" className="navBarMainStyle">
                                    <i style={{ color: "#269BCD" }} className="fas fa-th fa-lg"></i>
                                </Nav.Link>}

                            <Nav.Link href="/home" className={`${styles.navBarMainStyle}`}>Home</Nav.Link>

                            <div style={{ paddingRight: "15px" }}>
                                <NavDropdownMenu title="Our Products" id="collasible-nav-dropdown">
                                    {departmentList.dropDownDepartmentList != undefined ? departmentList.dropDownDepartmentList.map((dep: any, index: number) => {
                                        return (
                                            <>
                                                {
                                                    dep.subDepartmentList.length != 0 ?
                                                        <div style={{ padding: "3px" }}>
                                                            <DropdownSubmenu className={`${styles.submenuTitle}`} href="" title={`${dep.departmentName}`}>
                                                                <NavDropdown.Item onClick={() => {
                                                                    window.location.href = "/products/" + dep.departmentName.replace(/[/ ]/g, "_").toLowerCase();
                                                                }}><b>All {dep.departmentName}</b></NavDropdown.Item>
                                                                {

                                                                    dep.subDepartmentList.map((subDep: any, index: number) => {
                                                                        return (
                                                                            <NavDropdown.Item onClick={() => {
                                                                                window.location.href = "/products/" + dep.departmentName.replace(/[/ ]/g, "_").toLowerCase() + "/" + subDep.subDepartmentName.replace(/[/ ]/g, "_").toLowerCase();
                                                                            }}>{subDep.subDepartmentName}</NavDropdown.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </DropdownSubmenu>
                                                        </div>
                                                        :
                                                        <div style={{ padding: "3px" }}>
                                                            <NavDropdown.Item onClick={() => {
                                                                window.location.href = dep.departmentCode == "D12" ? "/pnpl" : "/products/" + dep.departmentName.replace(/[/ ]/g, "_").toLowerCase();
                                                            }}>{dep.departmentName}</NavDropdown.Item>
                                                            {/* <NavDropdown.Item onClick={() => {
                                                                window.location.href = "/OurSolutions";
                                                            }}>Our Solutions</NavDropdown.Item> */}
                                                        </div>
                                                }
                                            </>
                                        )
                                    }) : null}

                                </NavDropdownMenu>
                            </div>
                            <Nav.Link href="/offers" className={`${styles.navBarMainStyle}`}>Special Offers</Nav.Link>
                            <div style={{ paddingRight: "15px" }}>
                                <NavDropdownMenu title="Find us" id="collasible-nav-dropdown">
                                    <NavDropdown.Item onClick={() => window.location.href = "/findShowroom"}>Our Showrooms</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => window.location.href = "/helpDesk"}>Help Desk and Workshops</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => window.location.href = "/findDealers"}>Dealer Locations</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => window.open(cutomerPortal, '_blank')}>Customer Portal</NavDropdown.Item>
                                </NavDropdownMenu>
                            </div>
                            <Nav.Link href="/contact" className={`${styles.navBarMainStyle}`} hidden>My Account</Nav.Link>
                            <Nav.Link href="/contact" className={`${styles.navBarMainStyle}`} hidden>Blog</Nav.Link>
                            <Nav.Link href="/contact" className={`${styles.navBarMainStyle}`}>Contact Us</Nav.Link>
                            {/* <Nav.Link href="/blogs" className={`${styles.navBarMainStyle}`}>Blog</Nav.Link> */}
                            <Nav.Link href="/aboutus" className={`${styles.navBarMainStyle}`} hidden>About Us</Nav.Link>
                            <div style={{ paddingRight: "15px" }}>
                                <NavDropdownMenu title="About us" id="collasible-nav-dropdown">
                                    <NavDropdown.Item onClick={() => window.location.href = "/aboutus"}>JKOA at a Glance</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => window.location.href = "/afterSales"}>After Sales Services</NavDropdown.Item>
                                </NavDropdownMenu>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                    <Nav.Item className={`${styles.navSearchContainer}`}>
                        {responsive.isMobileSmallScreen || responsive.isMobileLargeScreen || responsive.isTabScreen ?
                            <>
                                <i className={`fa fa-search`} style={{ marginRight: "10px" }} onClick={() => { setShowSearchBar("mobile") }}></i>
                                <Modal show={showSearchBar == "mobile" ? true : false} onHide={() => { setShowSearchBar("none"); setShowSuggestions([]) }}
                                    size="sm" aria-labelledby="contained-modal-title-vcenter">
                                    <Modal.Body className={`${styles.searchBarMobileContainer}`}>
                                        <input className={`${styles.searchBarMobile}`} placeholder="Search"
                                            onChange={searchingDataFn} onKeyPress={hitEnter}></input>
                                        <div className={`${styles.searchContainerMobile} ${styles.fade}`} hidden={showSearchBar == "none" ? true : false}>
                                            {
                                                showSuggestions.map((item: any) => {
                                                    return (
                                                        <div className={`${styles.searchResultMobile}`}
                                                            onClick={() => window.location.href = "/productDetails?ic=" + item.itemCode + "&" + item.itemName.replace(/ /g, "_").toUpperCase()}>
                                                            {item.itemName}
                                                        </div>
                                                    )
                                                }
                                                )
                                            }
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </> :
                            <div className={`${styles.navSearchBar}`} onMouseEnter={() => { setShowSearchBar("web") }}
                                onMouseLeave={() => { setShowSearchBar("none") }}>
                                <input placeholder="Search" className={`${styles.navSearchBarInput}`}
                                    onChange={searchingDataFn} onKeyPress={hitEnter}></input>
                                <i className={`fa fa-search`} ></i>
                                <div className={`${styles.searchContainer} ${styles.fade}`} hidden={showSearchBar == "none" ? true : false}>
                                    {
                                        showSuggestions.map((item: any) => {
                                            return (
                                                <div className={`${styles.searchResult}`}
                                                    onClick={() => window.location.href = "/productDetails?ic=" + item.itemCode + "&" + item.itemName.replace(/ /g, "_").toUpperCase()}>
                                                    {item.itemName}
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </Nav.Item>
                </Container>
            </Navbar>
            {/* <SearchModal data={showSuggestions} show={showSearchBar == "mobile" ? true : false} onHide={() => { setShowSearchBar("none") }} /> */}
        </React.Fragment >
    )
}



export default NavBarWeb;
