import React from 'react';
import styles from "./DefaultFooterDesktopView.module.css";
import { Row, Col } from 'react-bootstrap';

/*-- Import dependencies --*/
import GetInTouch from '../../../getInTouch/GetInTouch';
// import Footerimg from '../../../../assets/images/Mobile_Banner_2021520125342.jpg';
import JKOALogo from '../../../../assets/images/logo/KeellsGreenLogo.png';
import { socialMediaLink } from '../../../../utilities/settings';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';

function DefaultFooterDescktopView() {
    const dispatch = useDispatch();
    var footerData: any = useSelector((state: RootState) => state.footerData.data);
    return (
        <React.Fragment>
            <div className={`${styles.footerDefault} ${styles.backgourndGradient}`}>
                <Col>
                    <Row className={`${styles.footerRow}`}>
                        <Col lg="2" md="2" xs="12" className={`${styles.footerLinks}`}>
                            <div className={`${styles.footerLlinkHeading}`}>Quick Links</div>
                            <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/home"}>Home</div>
                            <div className={`${styles.footerLinkItem}`}>Our Products</div>
                            <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/offers"}>Special Offer</div>
                            {/* <div className={`${styles.footerLinkItem}`}>My Account</div> */}
                            {/* <div className={`${styles.footerLinkItem}`}>Blog</div> */}
                            <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/aboutus"}>About Us</div>
                            {/* <div className={`${styles.footerLinkItem}`}>Careers</div> */}
                            <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/contact"} >Contact Us</div>
                            <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/termsAndConditions"} >Terms And Conditions</div>
                            <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/privacyPolicy"} >Privacy Policy</div>
                            <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/siteMap"} >Site Map</div>
                            <div hidden className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/pnpl"} >PNPL</div>
                        </Col>
                        <Col lg="2" md="3" xs="12" className={`${styles.footerLinks}`}>
                            <div className={`${styles.footerLlinkHeading}`}>Our Products</div>
                            {footerData.departments != undefined ? footerData.departments.map((dep: any) => {
                                return (
                                    <div onClick={() => {
                                        window.location.href = dep.departmentCode == "D12" ? "/pnpl" : "/products/" + dep.departmentName.replace(/[/ ]/g, "_").toLowerCase();
                                    }}
                                        className={`${styles.footerLinkItem}`} key={dep.departmentId}>
                                        {dep.departmentName}</div>
                                )
                            }) : null}
                            <div onClick={() => {
                                window.location.href = "/OurSolutions";
                            }}
                                className={`${styles.footerLinkItem}`}>
                                Our Solutions</div>
                        </Col>
                        <Col lg="2" md="2" xs="12" className={`${styles.footerLinks}`}>
                            <div className={`${styles.footerLlinkHeading}`}>Brands</div>
                            {footerData.brands != undefined ? footerData.brands.map((brand: any) => {
                                return (
                                    <div onClick={() => {
                                        window.location.href = "/products/" + brand.brandName.replace(/[/ ]/g, "_").toLowerCase();
                                    }}
                                        className={`${styles.footerLinkItem}`} key={brand.brandId}>
                                        {brand.brandName}</div>
                                )
                            }) : null}
                        </Col>
                        <Col className={`${styles.footerimg}`} lg="4" md="3" xs="12">
                            <img className={`img-fluid`} alt="footer" src={"https://sajkoaonline.blob.core.windows.net/jkoa/footer/Footer-web-image.png"}></img>
                        </Col>
                        <Col lg="2" md="2" xs="12" className={`${styles.footerLinks}`}>
                            <GetInTouch />
                        </Col>
                    </Row>
                    <Row className={`${styles.footerRow}`}>
                        <hr className={`${styles.seperator}`} />
                    </Row>
                    <Row className={`${styles.footerRow} ${styles.contactRow}`}>
                        <Col lg="3" md="3" xs="12">
                            <Row>
                                <Col className={`${styles.cotactDetailsDiv}`}><img style={{ width: "90px" }} className="img-fluid" alt="JKOA logo" src="https://sajkoaonline.blob.core.windows.net/jkoa/other/JKOA-logo.png"></img> <div className={`${styles.contactDetails}`}>
                                    <p style={{ margin: "0" }}>JOHN KEELLS OFFICE<br />AUTOMATION (PVT) LTD</p></div></Col>

                            </Row>
                        </Col>
                        <Col lg="3" md="3" xs="12">
                            <Row>
                                <Col className={`${styles.cotactDetailsDiv}`}><i className={`fas fa-map-marker-alt ${styles.locatorIcon}`} /><div className={`${styles.contactDetails}`}>
                                    <p style={{ margin: "10px 0 0 0" }}>90 UNION PLACE,</p>
                                    <p>COLOMBO 02, SRI LANKA</p>
                                </div></Col>

                            </Row>
                        </Col>
                        <Col lg="2" md="2" xs="12">
                            <Row>
                                <Col className={`${styles.cotactDetailsDiv}`}><i className={`fas fa-phone-alt ${styles.locatorIcon}`} /><div className={`${styles.contactDetails}`}>+94 11 2 313000</div></Col>

                            </Row>
                        </Col>
                        <Col lg="2" md="2" xs="12">
                            <Row>
                                <Col className={`${styles.cotactDetailsDiv}`}><i className={`fas fa-envelope ${styles.locatorIcon}`} /><div className={`${styles.contactDetails}`}>jkoa@keells.com</div></Col>

                            </Row>
                        </Col>
                        <Col lg="2" md="2" xs="12" className={`${styles.socialMediaCol}`}>
                            <i style={{ cursor: "pointer" }} className={`fab fa-facebook-f ${styles.locatorIcon}`} onClick={() => window.open(socialMediaLink.facebook, '_blank')} />
                            <i style={{ cursor: "pointer" }} className={`fab fa-linkedin-in ${styles.locatorIcon}`} onClick={() => window.open(socialMediaLink.linkedin, '_blank')} />
                            <i style={{ cursor: "pointer" }} className={`fab fa-instagram ${styles.locatorIcon}`} onClick={() => window.open(socialMediaLink.instagram, '_blank')} />
                            <i style={{ cursor: "pointer" }} className={`fab fa-youtube ${styles.locatorIcon}`} onClick={() => window.open(socialMediaLink.youtube, '_blank')} />
                            <i hidden style={{ cursor: "pointer" }} className={`fab fa-twitter ${styles.locatorIcon}`} />
                        </Col>
                    </Row>
                    <Row className={`${styles.footerRow}`}>
                        <hr className={`${styles.seperator}`} />
                    </Row>
                    <Row className={`${styles.footerRow} ${styles.fotterBottom}`}>
                        <Col><div >Copyright © {new Date().getFullYear()} Aves JKOA. All Rights Reserved</div></Col>
                        {/* <Col><div className={`${styles.conceptBy}`}>Concept and Design By Antyra Solutions</div></Col> */}
                    </Row>
                </Col>
            </div>
        </React.Fragment>
    )
}

export default DefaultFooterDescktopView;