import { messageBoxActionTypes } from "./messageBoxAction";

export interface messageBoxInterface {
    show: boolean,
    title: string,
    className: string,
    content: string,
    isConfirmation: boolean,
    callBackFunction: any
};

const initialState: messageBoxInterface = {
    show: false,
    title: "",
    className: "",
    content: "",
    isConfirmation: false,
    callBackFunction: null
};

export const messageBoxReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case messageBoxActionTypes.ShowMessageBoxType:
            return {
                show: action.payload.show,
                title: action.payload.title,
                className: action.payload.className,
                content: action.payload.content,
                isConfirmation: action.payload.isConfirmation,
                callBackFunction: action.payload.callBackFunction
            };
        case messageBoxActionTypes.ResetMessageBoxType:
            return initialState;
        default:
            return state;
    }
};