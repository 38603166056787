import axios from 'axios';
import { hideLoadingScreen, showLoadingScreen } from '../redux/loadingScreen/loadingScreenActions';
import { store } from '../redux/store';

const { REACT_APP_BACKEND_URL, REACT_APP_API_VERSION } = process.env;

const jkoaClient = axios.create({
    baseURL: `${REACT_APP_BACKEND_URL}/${REACT_APP_API_VERSION}`,
    // timeout: 1000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});


export const get = (path: string, params: {}, hideLoader: boolean) => {
    return new Promise((resolve) => {
        if (!hideLoader) store.dispatch(showLoadingScreen());
        jkoaClient.get(path, { params: params })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                console.log(error);
            })
            .then(() => {
                store.dispatch(hideLoadingScreen());
            })
    })
}

export const post = (path: string, body: {}, hideLoader: boolean) => {
    return new Promise((resolve) => {
        if (!hideLoader) store.dispatch(showLoadingScreen());
        jkoaClient.post(path, body)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                console.log(error);
            })
            .then(() => {
                store.dispatch(hideLoadingScreen());
            })
    })
}
