import React from 'react';
import styles from "./DefaultFooterMobView.module.css";
import { Row, Col } from 'react-bootstrap';

/*-- Import dependencies --*/
import GetInTouch from '../../../getInTouch/GetInTouch';
// import Footerimg from '../../../../assets/images/Mobile_Banner_2021520125342.jpg';
import JKOALogo from '../../../../assets/images/logo/KeellsGreenLogo.png';
import { socialMediaLink } from '../../../../utilities/settings';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';

function DefaultFooterMobView() {
    const dispatch = useDispatch();
    var footerData: any = useSelector((state: RootState) => state.footerData.data);

    return (
        <React.Fragment>
            <div className={`${styles.footerDefault} ${styles.backgourndGradient}`}>
                <Col>
                    <Row className={`${styles.footerRow}`}>
                        <Col lg="12" md="12" xs="12" className={`${styles.accordianTab}`}>
                            <div className={`${styles.tabs} ${styles.footerLinkHolder}`}>
                                <div className={`${styles.tab}`}>
                                    <input type="radio" id="rd1" name="rd" className={`${styles.rd}`} />
                                    <label className={`${styles.footerLinkHeading} ${styles.tabLabel}`} htmlFor="rd1">Quick Links</label>
                                    <div className={`${styles.tabContent}`}>
                                        <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/home"}>Home</div>
                                        <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/offers"}>Our Products</div>
                                        <div className={`${styles.footerLinkItem}`}>Special Offer</div>
                                        {/* <div className={`${styles.footerLinkItem}`}>My Account</div> */}
                                        {/* <div className={`${styles.footerLinkItem}`}>Blog</div> */}
                                        <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/aboutus"}>About Us</div>
                                        {/* <div className={`${styles.footerLinkItem}`}>Careers</div> */}
                                        <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/contact"}>Contact Us</div>
                                        <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/termsAndConditions"} >Terms And Conditions</div>
                                        <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/privacyPolicy"} >Privacy Policy</div>
                                        <div className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/siteMap"}>Site Map</div>
                                        <div hidden className={`${styles.footerLinkItem}`} onClick={() => window.location.href = "/pnpl"}>PNPL</div>
                                    </div>
                                </div>
                                <div className={`${styles.tab}`}>
                                    <input type="radio" id="rd2" name="rd" className={`${styles.rd}`} />
                                    <label className={`${styles.footerLinkHeading} ${styles.tabLabel}`} htmlFor="rd2">Our Products</label>
                                    <div className={`${styles.tabContent}`}>
                                        <div className={`${styles.footerLlinkHeading}`}>Our Products</div>
                                        {footerData.departments != undefined ? footerData.departments.map((dep: any) => {
                                            return (
                                                <div onClick={() => {
                                                    window.location.href = dep.departmentCode == "D12" ? "/pnpl" : "/products/" + dep.departmentName.replace(/[/ ]/g, "_").toLowerCase();
                                                }}
                                                    className={`${styles.footerLinkItem}`} key={dep.departmentId}>
                                                    {dep.departmentName}</div>
                                            )
                                        }) : null}
                                    </div>
                                </div>
                                <div className={`${styles.tab}`}>
                                    <input type="radio" id="rd3" name="rd" className={`${styles.rd}`} />
                                    <label className={`${styles.footerLinkHeading} ${styles.tabLabel}`} htmlFor="rd3">Brands</label>
                                    <div className={`${styles.tabContent}`}>
                                        <div className={`${styles.footerLlinkHeading}`}>Brands</div>
                                        {footerData.brands != undefined ? footerData.brands.map((brand: any) => {
                                            return (
                                                <div onClick={() => {
                                                    window.location.href = "/products/" + brand.brandName.replace(/[/ ]/g, "_").toLowerCase();
                                                }}
                                                    className={`${styles.footerLinkItem}`} key={brand.brandId}>
                                                    {brand.brandName}</div>
                                            )
                                        }) : null}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className={`${styles.footerimg}`} lg="12" md="12" xs="12">
                            <img className={`img-fluid`} alt="footer" src={"https://sajkoaonline.blob.core.windows.net/jkoa/footer/Footer-mobile-version-image.png"}></img>
                        </Col>
                        <Col lg="12" md="12" xs="12">
                            <GetInTouch />
                            <div className={`${styles.formGroupGetInTouch}`}>
                                <label className={`${styles.formLable}`}>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</label>
                            </div>
                        </Col>
                    </Row>
                    <Row className={`${styles.footerRow}`}>
                        <Col lg="12" md="12" xs="12"><hr /></Col>
                    </Row>
                    <Row className={`${styles.footerRow}`}>
                        <Col lg="12" md="12" xs="12" className={`${styles.contactDetails}`}><img style={{ width: "90px" }} className="img-fluid" src="https://sajkoaonline.blob.core.windows.net/jkoa/other/JKOA-logo.png" alt="JKOA logo"></img><div className={`${styles.footerlogoName}`}>
                            <p style={{ margin: "0" }}>JOHN KEELLS OFFICE<br />AUTOMATION (PVT) LTD</p></div></Col>
                        <Col lg="12" md="12" xs="12" className={`${styles.contactDetails}`}><i className={`fas fa-map-marker-alt ${styles.locatorIcon}`} /><div className={`${styles.footerlogoName}`}>
                            <p style={{ margin: "10px 0 0 0" }}>90 UNION PLACE,</p>
                            <p>COLOMBO 02, SRI LANKA</p></div></Col>
                        <Col lg="12" md="12" xs="12" className={`${styles.contactDetails}`}><i className={`fas fa-phone-alt ${styles.locatorIcon}`} /><div className={`${styles.footerlogoName}`}>+94 11 2 313000</div></Col>
                        <Col lg="12" md="12" xs="12" className={`${styles.contactDetails}`}><i className={`fas fa-envelope ${styles.locatorIcon}`} /><div className={`${styles.footerlogoName}`}>jkoa@keells.com</div></Col>
                        <Col lg="12" md="12" xs="12" className={`${styles.socialMediaCol}`}>
                            <i style={{ cursor: "pointer" }} className={`fab fa-facebook-f ${styles.locatorIcon}`} onClick={() => window.open(socialMediaLink.facebook, '_blank')} />
                            <i style={{ cursor: "pointer" }} className={`fab fa-linkedin-in ${styles.locatorIcon}`} onClick={() => window.open(socialMediaLink.linkedin, '_blank')} />
                            <i style={{ cursor: "pointer" }} className={`fab fa-instagram ${styles.locatorIcon}`} onClick={() => window.open(socialMediaLink.instagram, '_blank')} />
                            <i style={{ cursor: "pointer" }} className={`fab fa-youtube ${styles.locatorIcon}`} onClick={() => window.open(socialMediaLink.youtube, '_blank')} />
                            <i hidden style={{ cursor: "pointer" }} className={`fab fa-twitter ${styles.locatorIcon}`} />
                        </Col>
                    </Row>
                    <Row className={`${styles.footerRow}`}>
                        <Col lg="12" md="12" xs="12"><hr /></Col>
                    </Row>
                    <Row className={`${styles.footerRow} ${styles.footerBottom}`}>
                        <Col lg="12" md="12" xs="12">
                            <div className={`${styles.copyRights}`}>Copyright © {new Date().getFullYear()} Aves JKOA. All Rights Reserved</div>
                            {/* <div className={`${styles.copyRights}`}>Concept and Design By Antyra Solutions</div> */}
                        </Col>

                    </Row>
                </Col>
            </div>
        </React.Fragment>
    )
}

export default DefaultFooterMobView;