import React from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoadingScreen from './components/common/loadingScreen/LoadingScreen';
import { useSelector } from 'react-redux';
import { RootState } from './redux/rootReducer';
import "./assets/scss/defaultTheme.css";
import DefaultLayout from './defaultLayout/DefaultLayout';
import MessageBox from './components/common/messageBox/MessageBox';

function App() {

  const showLoadingScreen = useSelector((state: RootState) => state.loadingScreen.show);

  const loadingScreen = () => {
    return <LoadingScreen show={true} />
  }

  return (
    <div className="App">
      <BrowserRouter>
        <React.Suspense fallback={loadingScreen()}>
          <Switch>
            <Route path="/" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      <MessageBox />
      <LoadingScreen show={showLoadingScreen} />
    </div>
  );
}

export default App;
