import { searchDataActionTypes } from "./searchDataAction";

const initialState = {
    products: []
}

export const searchDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case searchDataActionTypes.SetProductsType:
            return { ...state, products: action.payload };
        default:
            return state;
    }
}