import { loadingScreenActionTypes } from './loadingScreenActions'

export interface LoadingScreenState {
    show: boolean
}

const initialState = {
    show: false
}

export const loadingScreenReducer = (state: LoadingScreenState = initialState, action: any) => {
    switch (action.type) {
        case loadingScreenActionTypes.HideLoadingScreenType:
            return { ...state, show: false };
        case loadingScreenActionTypes.ShowLoadingScreenType:
            return { ...state, show: true };
        default:
            return state;
    }
}