import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import routes from "../routes";
import './DefaultLayout.module.css';
import styles from './DefaultLayout.module.css';
/*-- Imports for redux --*/
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { updateResponsive, resetResponsive } from '../redux/responsive/responsiveAction';
import { setIsSiteLive } from '../redux/isSiteLive/isSiteLiveAction';

/*-- Import dependencies --*/
import LoadingScreen from '../components/common/loadingScreen/LoadingScreen';
import DefaultFooter from '../components/defaultFooter/DefaultFooter';
import { get } from '../utilities/apiClient';

import { responsiveScreenSizesEnum } from '../utilities/enums';
import NavBarWeb from '../components/NavBar/NavBarWeb/NavBarWeb';

function DefaultLayout() {
    var isSiteLive = useSelector((state: RootState) => state.isSiteLive);
    const showLoadingScreen = useSelector((state: RootState) => state.loadingScreen.show);
    const dispatch = useDispatch();


    useEffect(() => {
        responsiveFn();
        if (isSiteLive.data == undefined) {
            isWebSiteLive();
        }
    });

    function loadingScreen() {
        return <LoadingScreen show={true} />
    }

    function responsiveFn(): any {
        if (window.outerWidth <= responsiveScreenSizesEnum.mobileSmallScreen) {
            let screen = { isTabScreen: false, isMobileLargeScreen: false, isMobileSmallScreen: true }
            dispatch(updateResponsive(screen));
        } else if (window.outerWidth <= responsiveScreenSizesEnum.mobileLargeScreen) {
            let screen = { isTabScreen: false, isMobileLargeScreen: true, isMobileSmallScreen: false }
            dispatch(updateResponsive(screen));
        } else if (window.outerWidth <= responsiveScreenSizesEnum.tabScreenSize) {
            let screen = { isTabScreen: true, isMobileLargeScreen: false, isMobileSmallScreen: false }
            dispatch(updateResponsive(screen));
        } else {

            dispatch(resetResponsive());
        }
    }

    function isWebSiteLive() {
        get("GeneralModule/IsWebSiteLive", {}, false)
            .then(res => {
                var response: any = res;
                if (response.data.statusCode === 200) {
                    dispatch(setIsSiteLive(response.data.result));
                }
                else {
                    console.log(response.data.errorList)
                }
            });
    }

    return (
        <React.Fragment>
            {
                isSiteLive.data === undefined || isSiteLive.data.isSiteUp === true ?
                    <>
                        {
                            window.location.href.toUpperCase().includes("/OPENPAGE") ?
                                <>
                                    <BrowserRouter key={window.outerWidth}>
                                        <div className="default-page-load-area">
                                            <Suspense fallback={loadingScreen()}>
                                                <Switch>
                                                    {routes.map((route, index) => {
                                                        return route.component ? (
                                                            <Route key={index} path={route.path} component={route.component} />
                                                        ) : (null);
                                                    })}
                                                    <Redirect from="/" to="/home" />
                                                </Switch>
                                            </Suspense>
                                        </div>
                                    </BrowserRouter>
                                    <LoadingScreen show={showLoadingScreen} />
                                </>
                                :
                                <>
                                    <div className={`${styles.header}`}>
                                        <NavBarWeb />
                                    </div>
                                    <div className={`${styles.outer}`}>
                                        <BrowserRouter key={window.outerWidth}>
                                            <div className="default-page-load-area">
                                                <Suspense fallback={loadingScreen()}>
                                                    <Switch>
                                                        {routes.map((route, index) => {
                                                            return route.component ? (
                                                                <Route key={index} path={route.path} component={route.component} />
                                                            ) : (null);
                                                        })}
                                                        <Redirect from="/" to="/home" />
                                                    </Switch>
                                                </Suspense>
                                            </div>
                                        </BrowserRouter>
                                        <DefaultFooter />
                                    </div>
                                    <LoadingScreen show={showLoadingScreen} />
                                </>
                        }
                    </>
                    :
                    <div>
                        <img src={isSiteLive.data.devImg} style={{ width: "100%" }} />
                    </div>
            }

        </React.Fragment>
    )
}

export default DefaultLayout;