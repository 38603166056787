import { createStore, compose } from "redux";
import rootReducer from './rootReducer';

/** Persist redux state on refresh */
import { persistStore } from 'redux-persist';

const ReduxDevTools =
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__();

let _compose = compose(ReduxDevTools);

// export const store = createStore(rootReducer);
// -----> remove redux tool in production environment <-----
export const store = createStore(
    rootReducer,
    _compose);


export const persistedState = persistStore(store)
export default { store, persistedState };

