import React, { useEffect } from 'react';

/*-- Imports for redux --*/
import { useDispatch, useSelector } from 'react-redux';
import { setFooterData } from '../../redux/footerData/footerDataAction';
import { RootState } from '../../redux/rootReducer';
import { get } from '../../utilities/apiClient';

/*-- Import dependencies --*/
import DefaultFooterDesktopView from './defaultFooterView/defaultFooterDescktopView/DefaultFooterDesktopView';
import DefaultFooterMobView from './defaultFooterView/defaultFooterMobView/DefaultFooterMobView';

function DefaultFooter() {
    var footerData = useSelector((state: RootState) => state.footerData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (footerData.data.brands == undefined || footerData.data.departments == undefined) {
            getData();
        }
    }, [])

    function getData() {
        get("GeneralModule/GetFooterData", {}, false)
            .then(res => {
                var response: any = res;
                if (response.data.statusCode === 200) {
                    dispatch(setFooterData(response.data.result))
                }
                else {
                    console.log(response.data.errorList)
                }
            });
    }

    const responsive = useSelector((state: RootState) => state.responsive);
    return (
        <React.Fragment>
            {
                responsive.isMobileLargeScreen
                    || responsive.isMobileSmallScreen || responsive.isTabScreen
                    ? <DefaultFooterMobView />
                    : <DefaultFooterDesktopView />
            }
        </React.Fragment>
    )
}

export default DefaultFooter;