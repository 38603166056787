export enum loadingScreenActionTypes {
    ShowLoadingScreenType = "SHOW_LOADING_SCREEN",
    HideLoadingScreenType = "HIDE_LOADING_SCREEN",
}

export const showLoadingScreen = () => ({
    type: loadingScreenActionTypes.ShowLoadingScreenType
});

export const hideLoadingScreen = () => ({
    type: loadingScreenActionTypes.HideLoadingScreenType
});
