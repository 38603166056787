import { isSiteLiveActionTypes } from "./isSiteLiveAction";

const initialState = {
    data: undefined
}

export const isSiteLiveReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case isSiteLiveActionTypes.SetIsSiteLiveType:
            return { ...state, data: action.payload };
        default:
            return state;
    }
}