import React from "react";
import styles from "./LoadingScreen.module.css";

interface iLoadingScreen {
    show: boolean
}

export default function LoadingScreen(props: iLoadingScreen) {
    const show = props.show;
    return (
        <React.Fragment>
            {show ?
                <div className={styles.container}>
                    <i className={`fas fa-circle-notch fa-spin fa-5x ${styles.icons}`}></i>
                </div>
                : null}
        </React.Fragment>
    )
}