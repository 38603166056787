import React from 'react';

interface iroute {
    path: string
    name: string,
    component: any
}

const HomePage = React.lazy(() => import("./pages/homePage/HomePage"));
const ProductListingPage = React.lazy(() => import("./pages/productListingPage/ProductListingPage"));
const ContactUsPage = React.lazy(() => import("./pages/contactUsPage/ContactUsPage"));
const AboutUsPage = React.lazy(() => import("./pages/aboutUsPage/AboutUsPage"));
const LocationPage = React.lazy(() => import("./pages/locationPage/LocationPage"));
const HelpDeskPage = React.lazy(() => import("./pages/HelpDeskPage/HelpDeskPage"));
const OffersPage = React.lazy(() => import("./pages/offersPage/OffersPage"));
const ProductDetailsPage = React.lazy(() => import("./pages/productDetailsPage/ProductDetailsPage"))
const LocationPageDealer = React.lazy(() => import("./pages/locationPageDealer/LocationPageDealer"));
const LocationPageShowroom = React.lazy(() => import("./pages/locationPageShowroom/LocationPageShowroom"));
const OpenPage = React.lazy(() => import("./pages/OpenPage/OpenPage"));
const SiteMap = React.lazy(() => import("./pages/siteMap/SiteMap"));
const ProductCompare = React.lazy(() => import("./pages/productCompare/ProductCompare"));
const AfterSales = React.lazy(() => import("./pages/afterSales/AfterSales"));
const PNPL = React.lazy(() => import("./pages/PNPLPage/PNPLPage"));
const EventListing = React.lazy(() => import("./pages/eventListing/EventListingPage"));
const EventDetail = React.lazy(() => import("./pages/eventDetail/EventDetail"));
const CSRPage = React.lazy(() => import("./pages/csrPage/CSRPage"));
const TermsAndConditions = React.lazy(() => import("./pages/privacyPolicy/termsAndConditions"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacyPolicy/privacyPolicy"));
const QRVerification = React.lazy(() => import("./pages/QRVerification/QRVerification"));
const OurSolutions = React.lazy(() => import("./pages/ourSolutions/OurSolutions"));
const Blogs = React.lazy(() => import("./pages/blog/Blogs"));
const BlogDetailPage = React.lazy(() => import("./pages/blog/BlogDetailPage"));

const routes: iroute[] = [
    { path: "/home", name: "Home", component: HomePage },
    // { path: "/productListing/:listingType/:param1/", name: "ProductListing", component: ProductListingPage },
    //    { path: "/productListing?lt=1&v=1234", name: "ProductListing", component: ProductListingPage },
    { path: "/products", name: "ProductListing", component: ProductListingPage },
    { path: "/contact/:itemCode/:subject", name: "ContactUs", component: ContactUsPage },
    { path: "/contact", name: "ContactUs", component: ContactUsPage },
    { path: "/aboutus", name: "About Us", component: AboutUsPage },
    { path: "/productDetails", name: "ProductDetails", component: ProductDetailsPage },
    // { path: "/findUs", name: "FindUs", component: LocationPage },
    { path: "/helpDesk", name: "HelpDesk", component: HelpDeskPage },
    { path: "/offers/:id", name: "Offers", component: OffersPage },
    { path: "/offers", name: "Offers", component: OffersPage },
    { path: "/findDealers", name: "FindDealers", component: LocationPageDealer },
    { path: "/findShowroom", name: "FindShowroom", component: LocationPageShowroom },
    { path: "/OpenPage", name: "OpenPage", component: OpenPage },
    { path: "/siteMap", name: "SiteMap", component: SiteMap },
    { path: "/productCompare", name: "ProductCompare", component: ProductCompare },
    { path: "/afterSales", name: "AfterSales", component: AfterSales },
    { path: "/pnpl", name: "PNPL", component: PNPL },
    { path: "/eventListing", name: "EventListing", component: EventListing },
    { path: "/eventDetail", name: "EventDetail", component: EventDetail },
    { path: "/csr", name: "CSRPage", component: CSRPage },
    { path: "/termsAndConditions", name: "TermsAndConditions", component: TermsAndConditions },
    { path: "/privacyPolicy", name: "PrivacyPolicy", component: PrivacyPolicy },
    { path: "/qrverification", name: "QRVerification", component: QRVerification },
    // { path: "/OurSolutions", name: "OurSolutions", component: OurSolutions },
    // { path: "/blogs/:category", name: "Blogs", component: Blogs },
    // { path: "/blogs", name: "Blogs", component: Blogs },
    // { path: "/blog/:title", name: "Blog Detail", component: BlogDetailPage },
]

export default routes;