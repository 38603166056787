import { messageBoxInterface } from "./messageBoxReducer";

export enum messageBoxActionTypes {
    ShowMessageBoxType = "SHOW_MESSAGE_BOX",
    ResetMessageBoxType = "RESET_MESSAGE_BOX",
}

export const showMessageBox = (message: messageBoxInterface) => ({
    type: messageBoxActionTypes.ShowMessageBoxType,
    payload: message
});

export const resetMessageBox = () => ({
    type: messageBoxActionTypes.ResetMessageBoxType,
});
