import { responsiveActionTypes } from './responsiveAction'

export interface ResponsiveState {
    isTabScreen: boolean,
    isMobileLargeScreen: boolean,
    isMobileSmallScreen: boolean
}

const initialState: ResponsiveState = {
    isTabScreen: false,
    isMobileLargeScreen: false,
    isMobileSmallScreen: false,
};

export const responsiveReducer = (state: ResponsiveState = initialState, action: any) => {
    switch (action.type) {
        case responsiveActionTypes.UpdateResponsiveType:
            return {
                isTabScreen: action.payload.isTabScreen,
                isMobileLargeScreen: action.payload.isMobileLargeScreen,
                isMobileSmallScreen: action.payload.isMobileSmallScreen,
            };
        case responsiveActionTypes.ResetResponsiveType:
            return initialState

        default:
            return state;
    }
};
