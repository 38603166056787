import { urlDataActionTypes } from "./UrlDataAction";

const initialState = {
    data: {}
}

export const urlDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case urlDataActionTypes.SetUrlDataType:
            return { ...state, data: action.payload };
        default:
            return state;
    }
}